import {useNavigate} from "react-router-dom";

interface ListGroupProps{
    image: string;
    name: string;
    last_message: string;
}

function ChatBox({image, name, last_message}:ListGroupProps) {
    let navigate = useNavigate()
    const handleButtonClick = () => {
        navigate("/instant-messaging/messages")
    }
    return (
        <li className="list-group-item custom-list-item" onClick={handleButtonClick}>
            <div className="contact-img-div">
                <img src={image} alt="Contact Photo" className="contact-img"/>
            </div>
            <h2 className="contact-name">{name}</h2>
            <p className="last-message">{last_message}</p>
        </li>
    );
}

function BackButton () {
    let navigate = useNavigate()
    const handleButtonClick = () => {
        navigate("/instant-messaging/home")
    }
    return (
        <button className="btn btn-primary fixed-bottom fixed-left m-3" onClick={handleButtonClick}>Back</button>
    )
}

export {ChatBox, BackButton};