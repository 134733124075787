import React from 'react'
import {useNavigate} from "react-router-dom";
import axios from "axios";


const SubmitButton = () => {
    let navigate = useNavigate()

    const userData = () => {
        const url = "https://jadonpage.pythonanywhere.com/api/users/";

        const data = {
            username: "Jtp202",
            password: "doll",
            can_create_account: false,
            authenticated: false,
            can_create_chat: false,
            friend_search: "ah",
            message_to_send: "dg",
        }

        axios.post(url,
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            },
        ).then((res) => console.log(res))
    }

        const handleButtonCLick = () => {
        navigate("instant-messaging/home")
    }
    return (
        <button className="btn btn-primary login-btn" onClick={() => userData()}>Submit</button>
    )
}

const CreateAccount = () => {
    let navigate = useNavigate()
    const handleButtonCLick = () => {
        navigate("/instant-messaging/create-account")
    }
    return (
        <h3 className="create_account-h3 text-primary" onClick={handleButtonCLick}>Create Account</h3>
    )
}

const BackButton = () => {
    let navigate = useNavigate()
    const handleButtonCLick = () => {
        navigate("/")
    }
    return (
        <button className="btn btn-primary back-btn " onClick={handleButtonCLick}>Back</button>
    )
}


export {SubmitButton, CreateAccount, BackButton};

