import {useNavigate} from "react-router-dom";

interface Props{
    received: string
    sent: string
}

const Message = ({received, sent}: Props) => {
    return (
        <div>
                <p className="sent">{sent}</p>
                <p className="received">{received}</p>
        </div>
    )
}

const ExitButton = () => {
    let navigate = useNavigate()
    const handleButtonClick = () => {
        navigate("/instant-messaging/chat-list")
    }
    return (
        <button className="btn btn-primary message-btn" onClick={handleButtonClick}>Exit</button>
    )
}

export {Message, ExitButton}
