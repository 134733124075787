import "../css_styles/FriendRequests.css"
import {FriendRequest, BackButton} from "../components/FriendRequestComponents";

const FriendRequests = () => {
    const requests = ["Jon124", "Jane184"]

    return (
        <div className="requests-container">
            <h2 className="requests-h2">Friend Requests</h2>
                <ul className="list-group">
                    {requests.map((request) => (
                        <FriendRequest request_user={request}/>
                    ))}
                </ul>
            <BackButton/>
        </div>
    )
}
export default FriendRequests
