import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NavigationButtonProps {
    path: string;
    label: string;
    className: string;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ path, label, className }) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(path);
    };

    return (
        <button className={`btn btn-primary ${className}`} onClick={handleButtonClick}>
            {label}
        </button>
    );
};

const ExitButton: React.FC = () => <NavigationButton path="/" label="Exit App" className="exit-btn" />;
const FriendRequestButton: React.FC = () => (
    <NavigationButton path="/instant-messaging/friend-requests" label="Friend Requests" className="friend-btn" />
);
const AddFriendsButton: React.FC = () => (
    <NavigationButton path="/instant-messaging/add-friends" label="Add Friends" className="home-btn" />
);
const ViewChatsButton: React.FC = () => (
    <NavigationButton path="/instant-messaging/chat-list" label="View Chats" className="home-btn chat-btn" />
);

export { ExitButton, FriendRequestButton, AddFriendsButton, ViewChatsButton };
