import "../css_styles/Messages.css"
import {Message, ExitButton} from "../components/MessagesComponents";
import vite from "../assets/vite.svg"

const Messages = () => {
    const name = "James Douglas";
    const messages= ["hey man", "Im good hbu?", "thats good"]

    return (
        <div>
            <div>
                <div className="top-container">
                    <div className="box">
                    <ExitButton/>
                    </div>
                    <img className="message-img" src={vite} alt="Contact Photo"/>
                    <h2 className="messages-h2">{name}</h2>
                </div>
                <ul>
                    {messages.map((message) => (
                        <Message received={message} sent={"hows the weather over there, its bad here."}/>

                    ))}
                </ul>
                <div className="send-container fixed-bottom">
                    <input className="send-input" placeholder="Enter MessagesComponents"/>
                    <button className="send-btn">Send</button>
                </div>
            </div>
        </div>
    )
}
export default Messages
