import {useNavigate} from "react-router-dom";

const BackButton = () => {
    let navigate = useNavigate()
    const handleButtonClick = () => {
        navigate("/instant-messaging/home")
    }
    return (
        <button className="btn btn-primary add-button" onClick={handleButtonClick}>Back</button>
    )
}
export default BackButton
