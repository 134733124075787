import "../css_styles/Home.css"
import vite from "../assets/vite.svg"
import {AddFriendsButton, ExitButton, FriendRequestButton, ViewChatsButton} from "../components/HomeComponents";

const Home = () => {
    const friend_count = 10

    return (
        <div>
            <div className="home-container">
                <ExitButton/>
                <FriendRequestButton/>
            </div>
            <div className="home">
                <div className="home-container">
                    <img className="img-size" src={vite} alt="Profile"/>
                </div>
                <h2 className="home-h2">Jadon Page</h2>
                <h3 className="home-h3">Friends: {friend_count}</h3>
            </div>
            <div className="btn-container">
                <AddFriendsButton/>
                <ViewChatsButton/>
            </div>

        </div>

    )
}
export default Home
