import "../css_styles/AddFriends.css"
import BackButton from "../components/AddFriendsComponents";
const AddFriends = () => {
    return (
        <div>
            <BackButton/>
            <div className="add-container">
                <h2 className="add-h2">Add Friends</h2>
                <label className="add-label">Search: </label>
                <input className="add-input" placeholder="Search a username"/>
                <div>
                    <button className="btn btn-primary">Search</button>
                </div>
            </div>
        </div>
    )
}
export default AddFriends
