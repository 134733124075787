import yes from "../assets/checkmark.png"
import no from "../assets/close.png"
import {useNavigate} from "react-router-dom";
interface Props {
    request_user:string;
}

const FriendRequest = ({request_user}: Props) => {
    return (
        <li className="list-group-item">
            <h3>{request_user}</h3>
            <img className="request-yes-img" src={yes} alt="Yes"/>
            <img className="request-no-img" src={no} alt="No"/>
        </li>
    )
}

const BackButton = () => {
    let navigate = useNavigate()
    const handleButtonClick = () => {
        navigate("/instant-messaging/home")
    }
    return (
        <button className="btn btn-primary fixed-bottom fixed-left m-3" onClick={handleButtonClick}>Back</button>
    )
}
export {FriendRequest, BackButton}
