import "../css_styles/Login.css";
import {SubmitButton, Login, BackButton} from "../components/CreateAccountComponents";

const CreateAccount = () => {

    return (
        <div className="login-body">
            <BackButton/>
            <div className="login">
                <h2 className="login-h2">Create Account</h2>
                <form>
                    <label className="login-label">Username</label>
                    <input
                        type="text"
                        required
                        className="login-input"
                        placeholder="Enter your username"
                    />
                    <label className="login-label">Password</label>
                    <input
                        type="text"
                        required
                        className="login-input"
                        placeholder="Enter your password"
                        />
                    <label className="login-label"> Re-enter Password:</label>
                <input
                    type="text"
                    required
                    className="login-input"
                    placeholder="Re-enter your password"
                />
                <SubmitButton/>
                </form>
                <Login/>
            </div>
        </div>
    )
}
export default CreateAccount
