import React from 'react'
import {useNavigate} from "react-router-dom";

const SubmitButton = () => {
    let navigate = useNavigate()
    const handleButtonCLick = () => {
        navigate("/instant-messaging/home")
    }
    return (
        <button className="btn btn-primary login-btn" onClick={handleButtonCLick}>Submit</button>
    )
}

const Login = () => {
    let navigate = useNavigate()
    const handleButtonCLick = () => {
        navigate("/")
    }
    return (
        <h3 className="login-h3 text-primary" onClick={handleButtonCLick}>Login</h3>
    )
}

const BackButton = () => {
    let navigate = useNavigate()
    const handleButtonCLick = () => {
        navigate("/")
    }
    return (
        <button className="btn btn-primary back-btn " onClick={handleButtonCLick}>Back</button>
    )
}

export {SubmitButton, Login, BackButton}

