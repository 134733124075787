import "../css_styles/Login.css";
import { SubmitButton, CreateAccount, BackButton } from "../components/LoginComponents";
import { useState, useEffect } from "react";
import axios from 'axios'

const Login = () => {
    const [data, setData] = useState([]);
    const url = process.env.REACT_APP_DJANGO_URL;

    useEffect(() => {
        async function fetchData() {
            console.log(url);
            const response = await fetch(`${url}users`);
            const result = await response.json();
            console.log(result);
            setData(result);
        }
        fetchData();
    }, []);

    return (
        <div className="login-body">
            <BackButton />
            <div className="login">
                <h2 className="login-h2">Login</h2>
                <form>
                    <label className="login-label">Username</label>
                    <input
                        type="text"
                        required
                        className="login-input"
                        placeholder="Enter your username"
                    />
                    <label className="login-label">Password</label>
                    <input
                        type="text"
                        required
                        className="login-input"
                        placeholder="Enter your password"
                    />
                    <SubmitButton />
                </form>
                <CreateAccount />
            </div>
        </div>
    );
}

export default Login;
