import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Login from "./pages/Login";
import FriendRequests from "./pages/FriendRequests";
import AddFriends from "./pages/AddFriends";
import ChatList from "./pages/ChatList";
import Messages from "./pages/Messages";
import CreateAccount from "./pages/CreateAccount";


function App() {
    const base_url = "/instant-messaging"

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path={base_url + "/home"} index element={<Home/>}/>
                    <Route index element={<Login/>}/>
                    <Route path={base_url + "/friend-requests"} index element={<FriendRequests/>}/>
                    <Route path={base_url + "/add-friends"} index element={<AddFriends/>}/>
                    <Route path={base_url + "/chat-list"} index element={<ChatList/>}/>
                    <Route path={base_url + "/messages"} index element={<Messages/>}/>
                    <Route path={base_url + "/create-account"} index element={<CreateAccount/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    )
}
export default App;