import {ChatBox, BackButton} from "../components/ChatListComponents";
import "../css_styles/ChatList.css"
import vite from "../assets/vite.svg"

function ChatList() {
    const contacts = ['John', 'Jake']
    return (
        <div>
            <h1 className="custom-header">Chats</h1>
            <ul className="list-group">
                {contacts.map((contact) => (
                    <ChatBox image={vite} name={contact} last_message={"Hey"}/>
                ))}
            </ul>
            <BackButton/>
        </div>
    )
}

export default ChatList;